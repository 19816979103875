/**
 * Service
 */

.testimonial {
  &-slider{
    .slick-list{
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  &{
    margin: 0 15px;
  }

  &-content {
    min-height: 150px;
    display: flex;
    align-items: center;
    padding: 30px;
    margin-bottom: 35px;
    position: relative;
    border-left: 5px solid $color-border;
    background: lighten($color-bg-body, 5);

    &::after {
      content: "";
      position: absolute;
      left: 30px;
      top: 100%;
      border-style: solid;
      border-width: 12px;
      border-color: lighten($color-bg-body, 5) transparent transparent lighten($color-bg-body, 5);
    }

    p {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }

  &-author {
    h5 {
      margin-bottom: 0;
    }

    h6 {
      margin-bottom: 0;
      color: $color-body;
    }
  }

  @media #{$layout-laptop}{
    &-content{
      padding: 20px;
    }
  }

  @media #{$layout-tablet}{
    &-content{
      p{
        font-size: 1.1rem;
      }
    }
  }

  @media #{$layout-mobile-lg}{
    &-content{
      p{
        font-size: 1.1rem;
      }
    }
  }

  @media #{$layout-mobile-sm}{
    &-content{
      padding: 20px;
    }
  }
}
